/**
 * External dependencies
 */
import React from "react";
import { Row, Col, Accordion } from "react-bootstrap";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import GetURL from "../../components/common/site/get-url"
/**
 * Internal dependencies
 */
import Socials from "../socials/socials";
import logos from "./static/logos";
import { mainNavigation, propertySearches } from "./static/nav-data";
import useFooterLogos from "./use-footer-logos";
import { convertGatsbyImageNodes } from '../../utils/gatsby-images';
import { useStaticQuery, graphql } from "gatsby"
import { toCapitalize } from "@components/common/utils";
import { StarberryIcons } from "../../components/icons/index";
import "./styles/_index.scss";
import {
  OFFICE_PAGE_URL
} from "../../components/common/site/constants"
import CookieConsent from "../CookieConsent/CookieConsent"
import PopularSearchStatic from "./popular-search-static";
import PopularSearchDynamic from "./popular-search-dynamic";
import PopularSearchOffice from "./popular-search-office";
import PopularSearchNewHomes from "./popular-search-new-homes";
const Footer = (props) => {
  const year = new Date().getFullYear()
  const logoQueryData = useFooterLogos();
  const images = convertGatsbyImageNodes(logoQueryData);
  const logoParts = [logos.slice(0, 4), logos.slice(4)];
  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      clientLogo(publicationState: LIVE) {
        Client_Logo {
          Client_Url
          Client_Image {
            alternativeText
            url
          }
        }
      }
      contact(publicationState: LIVE) {
        Facebook_Link
        Instagram_Link
        LInkedin_Link
        Twitter_Link
      }
      offices(sort:"Sort:ASC", publicationState: LIVE, where: {Publish: true}) {
        Office_Name
        URL
      }
      menuFooter(publicationState: LIVE) {
        Publish
        Footer_Menu_Block {
          Label
          Url {
            Alias
          }
          Sub_Footer_Menu {
            Label
            Url {
              Alias
            }
            Link
          }
        }
      }

    }
  }
`)
  return (
    <footer className="footer pt-40 pb-40">
      <CookieConsent />
      <div className="shell">
        <div className="footer__inner">
          <Row>
            <Col>
              <Row>
                <Col lg className="footer__nav">
                  <Accordion flush>
                    <Row>
                      {
                        data?.glstrapi?.menuFooter?.Footer_Menu_Block?.map((acc, index) => (
                          <Col lg key={index}>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>
                                {acc.Url?.Alias ?
                                  <GetURL label={acc.Label} alias={acc.Url?.Alias} />
                                  : acc.Label
                                }
                              </Accordion.Header>
                              <Accordion.Body>
                                {index === 2 ?
                                  data?.glstrapi?.offices?.map((item, i) => {
                                    return (
                                      <Link to={`${OFFICE_PAGE_URL?.alias}/${item?.URL+'/'}`}>
                                        {toCapitalize(item?.Office_Name)}
                                      </Link>
                                    )
                                  })
                                  :

                                  acc.Sub_Footer_Menu.map((i, id) =>{
                                    if(index === 3 && acc.Sub_Footer_Menu.length === (id+1)){
                                      return <a href="javascript:void(0);" id="open_preferences_center">Update Cookies Preferences</a>
                                    }else{
                                      return  <GetURL customLink={i.Link ? true : false} link={i.Link} label={i.Label} alias={i.Url?.Alias} />
                                    }
                                   
                                    
                                  }
                                   
                                  )}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Col>
                        ))
                      }
                    </Row>
                  </Accordion>
                </Col>

                <Col md={12} className="d-none d-md-block">
                  <Row className="flex-xl-nowrap align-items-center">
                    {
                      data?.glstrapi?.clientLogo?.Client_Logo?.map((arr, index) => (
                        <Col key={index} xs="auto" className="footer__logo-col d-flex flex-nowrap">
                          <div className="d-flex align-items-center flex-nowrap">
                            <a className="footer__logo-wrapper" href={arr.Client_Url}>
                              <img src={arr.Client_Image?.url} alt={arr.Client_Image?.alternativeText?arr.Client_Image?.alternativeText+" - Strakers Estate Agents logo"
                              :"Client logo - Strakers Estate Agents logo"} />
                            </a>
                          </div>
                        </Col>
                      ))
                    }
                  </Row>
                </Col>

                <Col xs="auto" lg={{ order: 'first' }} className="footer__socials">
                  <Socials data={data?.glstrapi?.contact} />

                  <div className="footer__privacy">
                    <p>All content © Copyright {year} Strakers.</p>
                    <p>All rights reserved.</p>
                    <p className="starberry">Site by <a href="https://starberry.tv/">
                      <StarberryIcons iconName="starberryIcon" className="bi" />
                    </a></p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="footer__property-searches">
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Popular Property Searches</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    {/* {
                      propertySearches.map((property, index) => (
                        <Col lg key={index}>
                          {property.map((i, id) => <Link to={i.href} key={id}>{i.text}</Link>)}
                        </Col>
                      ))
                    } */}
                    {
                    props.type === "new-homes" ?
                    <PopularSearchNewHomes searchtype={props.searchtype} searchBedroomfield={props.searchBedroomfield} searchPtype={props.searchPtype} Searcharea={props.Searcharea} />
                    :
                    (props.searchtype === 'sales') || (props.searchtype === 'lettings')?
                    <PopularSearchDynamic searchtype={props.searchtype} searchBedroomfield={props.searchBedroomfield} searchPtype={props.searchPtype} Searcharea={props.Searcharea} />
                    :
                    props.searchtype === "office" ?
                    <PopularSearchOffice area={props?.area} />
                    :
                    <PopularSearchStatic popularSearch={props.Popular_Search_Type} />
                  }
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div >
    </footer >
  )
}

export default Footer;