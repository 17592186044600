import React from "react";
import { DefaultIcons as defaults } from './defaults';

export const StarberryIcons = (props) => {
    const {
        className,
        iconName,
        iconStyle
    } = props;

    if (undefined !== iconName) {
        const Icon = defaults[iconName];

        if (undefined !== Icon) {
            return <Icon className={className} style={iconStyle} />;
        } else {
            console.warn(`Icon ${iconName} not found`);
            const ErrorIcon = defaults["errorIcon"];
            return <ErrorIcon className={className} style={iconStyle} />;
        }
    }

    console.warn(`Icon ${iconName} not found`);
    const ErrorIcon = defaults["errorIcon"];
    return <ErrorIcon className={className} style={iconStyle} />;

};

export default StarberryIcons;
