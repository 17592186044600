/**
 * External dependencies
 */
import React from "react"

const BurgerMenu = ({ onClick }) => (
  <button type="button" className="burger-menu clear-btn position-relative d-flex align-items-center justify-content-center" onClick={onClick}>
    <div className="burger-menu__icon">
      <div className="burger-menu__icon-inner"></div>
    </div>
    <div className="menu-txt">
    Menu
    </div>
  </button>
)

export default BurgerMenu
