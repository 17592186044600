import React from "react"
import { Link } from "gatsby"
import { Accordion, Col, Modal, Row } from "react-bootstrap"
import GetURL from "../../components/common/site/get-url"
/**
 * Internal dependencies.
 */
 import { useStaticQuery, graphql } from "gatsby"

import Socials from "../socials/socials"
import "./styles/_index.scss"
import {
  OFFICE_PAGE_URL
} from "../../components/common/site/constants"
import { toCapitalize } from "@components/common/utils";
import { navigate } from "@reach/router"
const HeaderMenu = ({ branches, data, menuBurgers, show, onClick, href }) => {
  // const linkdata = useStaticQuery(graphql`
  //   query {
  //     glstrapi {
  //       allMenus(publicationState: LIVE) {
  //         Alias
  //         URL
  //         Label
  //         Main_Parent {
  //           Alias
  //           URL
  //         }
  //         Sub_Parent {
  //           Alias
  //           URL
  //         }
  //         Link_Type
  //       }
  //     }
  //   }
  // `)

  // var PageURL;
  // var URL;
  // const ondoubleclick = (alias) => {
    
  //   PageURL = linkdata.glstrapi?.allMenus
  //   .filter(item => item?.Alias === alias)
  //   .pop()

  // URL = PageURL?.URL

  // if (PageURL?.Main_Parent != null && PageURL?.Sub_Parent == null) {
  //   URL = PageURL?.Main_Parent?.URL + "/" + URL
  // } else if (PageURL?.Main_Parent != null && PageURL?.Sub_Parent != null) {
  //   URL = PageURL?.Main_Parent?.URL + "/" + PageURL?.Sub_Parent?.URL + "/" + URL
  // }

  // navigate("/"+URL)

  // }
return (
  <Modal show={show} fullscreen={true} dialogClassName="header-menu dark-modal">
    <Modal.Body>
      <button onClick={onClick} className="header-menu__close">
        <i className="icon icon--close" />
      </button>
      <div className="header-menu__content">
        <Row className="align-items-start g-0 justify-content-md-between">
          
          <Col md="8" lg="6">
            <Accordion>
              {menuBurgers?.map((item, i)=>{
                return(
                  <Accordion.Item eventKey={i}>
                    {item?.Menu_Parent?.Sub_Burger_Menu?.length > 0 ?
                    <>
                  <Accordion.Header 
                  // onDoubleClick={(e) => ondoubleclick(item.Menu_Parent?.Parent_Url?.Alias)}
                  >
                  {item.Menu_Parent?.Parent_Url?.Alias ? <GetURL 
                  doubleClick
                   label={item.Menu_Parent?.Parent_Label} alias={item.Menu_Parent?.Parent_Url?.Alias} /> : item.Menu_Parent?.Parent_Label}                   
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      {item?.Menu_Parent?.Sub_Burger_Menu?.map((list, j) => {
                        return(
                      <li className="header-link-small">
                        <GetURL onClick={onClick} link={list?.Link} customLink={list.Link} class="is-active" label={list.Label} alias={list.Url?.Alias} />
                      {/* <Link activeClassName="is-active" to="/">
                        Buy with us
                      </Link> */}
                    </li>)
                      })}
                    </ul>
                  </Accordion.Body>
                  </>
                  :
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button className="accordion-button collapsed bg-none">
                      {item.Menu_Parent?.Parent_Url?.Alias ? <GetURL 
                  doubleClick
                   label={item.Menu_Parent?.Parent_Label} alias={item.Menu_Parent?.Parent_Url?.Alias} /> : item.Menu_Parent?.Parent_Label}                   
                      </button>
                    </h2>
                  </div>
              }
                </Accordion.Item>
                )
              })}
            </Accordion>
            {/* <ul>
              <li className="header-link">
                <Link activeClassName="is-active" to="/">
                  rent
                </Link>
              </li>
              <li className="header-link">
                <Link activeClassName="is-active" to="/">
                  landlords
                </Link>
              </li>
              <li className="header-link">
                <Link activeClassName="is-active" to="/">
                  land &amp; new homes
                </Link>
              </li>
              <li className="header-link">
                <Link activeClassName="is-active" to="/">
                  auctions
                </Link>
              </li>
              <li className="header-link">
                <Link activeClassName="is-active" to="/news">
                  news &amp; videos
                </Link>
              </li>
              <li className="header-link">
                <Link activeClassName="is-active" to="/">
                  about
                </Link>
              </li>
              <li className="header-link">
                <Link activeClassName="is-active" to="/">
                  contact
                </Link>
              </li>
            </ul> */}
          </Col>
          <Col md="4" lg="auto" className="d-none d-md-block">
            <h5><Link onClick={onClick} className="offie" to={`${OFFICE_PAGE_URL?.alias}/`}>our branches</Link></h5>
            <ul>
              {branches?.map((item, i) => {
                return(
                  <li className="header-link-small">
                  <Link onClick={onClick} activeClassName="is-active" to={`${OFFICE_PAGE_URL?.alias}/${item?.URL}/`}>
                    {toCapitalize(item?.Office_Name)}
                  </Link>
                </li>  
                )
              })}
            </ul>

            <Socials data={data} />
          </Col>
        </Row>
      </div>
    </Modal.Body>
  </Modal>
)
            }
export default HeaderMenu
