const logos = [
  {
    image: 'arla',
    href: '/'
  },
  {
    image: 'naea',
    href: '/'
  },
  {
    image: 'nava',
    href: '/'
  },
  {
    image: 'ombudsman',
    href: '/'
  },
  {
    image: 'tsi',
    href: '/'
  },
  {
    image: 'rightmove',
    href: '/'
  },
  {
    image: 'on-the-market',
    href: '/'
  },
  {
    image: 'zoopla',
    href: '/'
  }
];

export default logos;