import React, { useState,useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
const PopularSearchOffice = (props) => {
  //Get values from refine search
	const [ptype,setPtype] = useState(false)
  const [searchbed,setsearchbed] = useState(false)
 

  var searchaction = ''
  var URL = ''
  var ptypeURL = ''
  var bedURL = ''
  var bedtypeText= ''
  var area = 'Wiltshire'
  var typeText = 'Property'
  var searcharea = ''
  var searchareaText=''
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }  
if(props?.area && props?.area !== "Auctions" && props?.area !== "Sales Progression" && props?.area !== "lettings head office") {
    area = props.area
    area = capitalizeFirstLetter(props?.area?.replace(/-/g, ' '))
  }
  return (<React.Fragment>
        <Col lg className="wysiwyg-block">    
        <a className="footer_scroll_top" href={`/property/for-sale/in-${area?.toLowerCase()?.replace(/\s/g , '-')}/`}>Property for sale in {area}</a>
        <a className="footer_scroll_top" href={`/property/for-sale/in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-house/`}>Houses for sale in {area}</a>
        <a className="footer_scroll_top" href={`/property/for-sale/in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-apartment/`}>Apartments for sale in {area}</a>
        <a className="footer_scroll_top" href={`/property/for-sale/in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-bungalow/`}>Bungalows for sale in {area}</a>
        <a className="footer_scroll_top" href={`/property/for-sale/in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-flat/`}>Flats for sale in {area}</a>
            
          
          
          </Col>  

          <Col lg className="wysiwyg-block">    
        <a className="footer_scroll_top" href={`/property/to-rent/in-${area?.toLowerCase()?.replace(/\s/g , '-')}/`}>Property to rent in {area}</a>
        <a className="footer_scroll_top" href={`/property/to-rent/in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-house/`}>Houses to rent in {area}</a>
        <a className="footer_scroll_top" href={`/property/to-rent/in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-apartment/`}>Apartments to rent in {area}</a>
        <a className="footer_scroll_top" href={`/property/to-rent/in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-bungalow/`}>Bungalows to rent in {area}</a>
        <a className="footer_scroll_top" href={`/property/to-rent/in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-flat/`}>Flats to rent in {area}</a>
            
          
          
          </Col>  
          <Col lg className="wysiwyg-block">
          <a href="/about/">About us</a>
            <a href="/about/customer-reviews/">Testimonials</a>
            <a href="/latest-property-news/">Latest property news</a>
            <a href="/about/meet-our-team/">Meet the team</a>
              <a href="/about/area-guide/">Areas we cover</a>
       </Col>
        <Col lg className="wysiwyg-block">
        
        
        <a href={`/about/our-branches/`}>Estate agents in {area}</a>
        <a href={`/property-landlords/property-maintenance/`}>Property maintenance in {area}</a>
        <a href={`/property-auctions/for-sale/`}>Property auctions in {area}</a>
        <a href={`/sell-your-property/property-valuation-in-wiltshire/`}>Property valuation in {area}</a>
        <a href={`/about/estate-agent-jobs-in-wiltshire/`}>Estate agent jobs in {area}</a>
        
        </Col>
        
  </React.Fragment>)

}

export default PopularSearchOffice;
