/**
 * External dependencies
 */
import React from "react"
import { Row, Col } from "react-bootstrap"

/**
 * Internal dependencies
 */
import socials from "./static/socials"
import "./styles/_index.scss"

const Socials = (props) => (
  <Row className="socials">
      <Col xs="auto" className="socials__col" key={'twitter'}>
        <a target={"_blank"} href={props?.data?.Twitter_Link} className="d-block position-relative">
          <i
            className={`icon icon--twitter position-absolute top-50 start-50 translate-middle`}
          ></i>
        </a>
      </Col>
      <Col xs="auto" className="socials__col" key={'facebook'}>
        <a target={"_blank"} href={props?.data?.Facebook_Link} className="d-block position-relative">
          <i
            className={`icon icon--facebook position-absolute top-50 start-50 translate-middle`}
          ></i>
        </a>
      </Col>
      <Col xs="auto" className="socials__col" key={'instagram'}>
        <a target={"_blank"} href={props?.data?.Instagram_Link} className="d-block position-relative">
          <i
            className={`icon icon--instagram position-absolute top-50 start-50 translate-middle`}
          ></i>
        </a>
      </Col>
      <Col xs="auto" className="socials__col" key={'linkedin'}>
        <a target={"_blank"} href={props?.data?.LInkedin_Link} className="d-block position-relative">
          <i
            className={`icon icon--linkedin position-absolute top-50 start-50 translate-middle`}
          ></i>
        </a>
      </Col>
  </Row>
)

export default Socials
