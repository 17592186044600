import React, { forwardRef } from "react"

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <a
    href="/"
    ref={ref}
    onMouseOver={e => {
      e.preventDefault()
      onClick(e)
    }}
    
    // onClick={e => {
    //   e.preventDefault()
    //   onClick(e)
    // }}

    // onMouseLeave={e => {
    //   e.preventDefault()
    //   onClick(e)
    // }}
    className="menu-link"
  >
    {children}
  </a>
))

export default CustomToggle
