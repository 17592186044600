import { useStaticQuery, graphql } from "gatsby";
import { defaultArea } from "@constants";
import _ from "lodash"
import { pickBy, identity, isArray, get, isEmpty, isString, find } from "lodash"
export const getPriceStr = ( price ) => {
  let priceStr = '';
  if (price.min && price.max) {
      priceStr = " between " + `£` + addCommas(price.min) + " and " + `£` + addCommas(price.max);
  } else if (price.max) {
      priceStr = " under " + `£` + addCommas(price.max);
  } else if (price.min) {
      priceStr = " over " + `£` + addCommas(price.min);
  }
  return priceStr;
}

function addCommas(num) {
  var str = num.toString().split('.');
  if (str[0].length >= 4) {
      // add comma every 3 digits before decimal
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  /* Optional formating for decimal places
  if (str[1] && str[1].length >= 4) {
      // add space every 3 digits after decimal
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }*/
  return str.join('.');
}

export const ACTION_TYPES = {
  sales: " for sale in ",
  lettings: " to rent in ",
  salessold: " Sold ",
  lettingslet: " Let ",
  auctionsold: "",
  newhome: "for sale",
  oldhome: ""

}

export const ACTION_DESC_TYPES = {
  sales: " for sale ",
  lettings: " to rent ",
}

export const pTypeBase = {
  sales: "/property/for-sale/",
  lettings: "/property/to-rent/",
  past: "/property-auctions/previous-auction-results/",
  future: "/property-auctions/for-sale/",
  newhome: "/new-homes/",
}

export const buildingTypes = {
    "apartment" : "Apartments",
    "barn-conversion" : "Barn conversion",
    "bungalow" : "Bungalows",
    "detached-house" : "Detached houses",
    "flat-apartment": "Flats and Apartments",
    "house" : "House",
    "newhomes": "New homes",
    "semi-detached-house": "Semi detached houses",
    "terraced-house": "Terraced houses",
    "townhouse": "Townhouses",
    "retirement-property" : "Retirement property",
}

export const toTitleCase = (text) => {
  let _text = text || '';
  if ( _text ) {
    _text = _text.trim().split(' ');
    if ( _text.length > 0 ) {
      _text = _text.map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(' ');
    }
  }
  return _text.replaceAll(' And ', ' and ');
}

export const makeLowerCaseExceptArea = (text) => {
  if (text.match(/for sale/)) {
     let data = text.split('for sale');
     return data[0].toLowerCase() + ' for sale ' + data[1];
  }
  if (text.match(/to rent/)) {
     let data = text.split('to rent');
     return data[0].toLowerCase() + ' to rent ' + data[1];
  }
  return text;
}

// TODO: hard coded details to be removed/replaced with common variables
export const parseSearchUrl = (pType, location, rstype) => {
  if (location) {
      var query = '';
      let pathUri_main = location.split(rstype ? pTypeBase[rstype].slice(0, -1) : pTypeBase[pType].slice(0, -1))
      var areaVal = ''
      var bedVal = ''
      var minpriceVal = ''
      var maxpriceVal = ''
      var typeVal = ''
      var mustIncludeVal = ''
      var includeSold = ''
      var includeLet = ''
      var retirement = ''
      var hidenewhome = ''
      var shownewhome = ''
      var radiusVal = ""
      // debugger
      // console.log(pathUri_main)
      if( pathUri_main[1] ) { // following could be regexp
        let pathUri = pathUri_main[1].split('/');

        //lets loop
        for(let vi = 1; vi <= pathUri.length; vi++ ) {
          // check for area
          if( typeof pathUri[vi] === "undefined" ) {continue;}
          // for-sale
          if ( pathUri[vi].indexOf("property/for-sale") >= 0 ) {
            // its area
            pType = 'sales';
          }
          if ( pathUri[vi].indexOf("property/to-rent") >= 0 ) {
            // its area
            pType = 'lettings';
          }
          if ( pathUri[vi].indexOf("property-auctions/for-sale") >= 0 ) {
            // its area
            pType = 'auctioncurrent';
          }
          if ( pathUri[vi].indexOf("property-auctions/previous-auction-results") >= 0 ) {
            // its area
            pType = 'auctionprevious';
          }
          if ( pathUri[vi].indexOf("new-homes") >= 0 ) {
            // its area
            pType = 'newhome';
          }
          
          if ( pathUri[vi].indexOf("in-") == 0 ) {
            // its area
            areaVal = pathUri[vi].replace("in-","").replaceAll("-"," ");
          }
          if ( pathUri[vi].indexOf("-and-more-") >= 0 ) {
            // its bedrooms
            bedVal = pathUri[vi].replace("-and-more-bedrooms","")
          }
          if ( pathUri[vi].indexOf("studio") >= 0 ) {
            // its studio bedroom
            bedVal = 0
          }
          if ( pathUri[vi].indexOf("between-") >= 0 ||
               pathUri[vi].indexOf("over-") >= 0 ||
               pathUri[vi].indexOf("under-") >= 0
             ) {
            // its price
            let priceFilt1 = pathUri[vi].split('over-');
            if( priceFilt1[1] ) {
              minpriceVal = priceFilt1[1]
            }
            let priceFilt2 = pathUri[vi].split('under-');
            if( priceFilt2[1] ) {
              maxpriceVal = priceFilt2[1]
            }
            let priceFilt3 = pathUri[vi].split('between-');
            if( priceFilt3[1] ) {
              let priceFilt4 = priceFilt3[1].split('-and-');
              minpriceVal = priceFilt4[0]
              maxpriceVal = priceFilt4[1]
            }
          }
          // Must Include
          if (pathUri[vi].indexOf("with-") >= 0) {
            var mustIncludeArrfilt = pathUri[vi].replace("with-", "")
            mustIncludeVal = mustIncludeArrfilt.split("-and-")
          }
          let priceFilt5 = pathUri[vi].split('type-');
          if( priceFilt5[1] ) {
            typeVal = priceFilt5[1]
          }

          if (pathUri[vi].indexOf("include-sold") >= 0) {
              includeSold = true
          }

          if (pathUri[vi].indexOf("include-let") >= 0) {
            includeLet = true
        }
        if (pathUri[vi].indexOf("hide-retirement") >= 0) {
          retirement = true
      }
      if (pathUri[vi].indexOf("hide-newhomes") >= 0) {
        hidenewhome = true
    }
    if (pathUri[vi].indexOf("show-newhomes") >= 0) {
      shownewhome = true
  }
    

          // Radius
          if (pathUri[vi].indexOf("radius-") >= 0) {
              radiusVal = pathUri[vi].replace("radius-", "").replace("-miles", "")
          }
        }
      }
      console.log("data radius", radiusVal)
    // If no area send default to set default loaction
    // We could send defaut location
    if (!areaVal || areaVal === 'undefined') {
        areaVal = defaultArea.slug; // TODO
    }

    var areaAlias = areaVal.replaceAll(' ', '-');
    return {
      pType: pType,
      areas: areaVal,
      areas_alias: areaAlias,
      bedrooms: bedVal,
      type: typeVal,
      radius: ((areaVal.replaceAll(' ', '-')) ===  defaultArea.slug && (!radiusVal || radiusVal === "")) ? '' : radiusVal === "half" ? "half" : radiusVal === "quarter" ? "quarter" :  parseInt(radiusVal),
      mustInclude: mustIncludeVal,
      includeSold: includeSold,
      includeLet: includeLet,
      retirement: retirement,
      hidenewhome: hidenewhome,
      shownewhome: shownewhome,
      price: {
        min: minpriceVal,
        max: maxpriceVal,
      },
      extra: {
        bedPlus: false,
        areasTitleCase: true
      }
    }
  }
}

// TODO: hard coded details to be removed/replaced with common variables
export const savedSearchParams = (params) => {
  const {areas, bedrooms, pType, type, price, mustInclude} = params;

  return {
    department: "residential",
    search_type: pType,
    areas: areas,
    bedroom: bedrooms,
    minPrice:  price.min,
    maxPrice:  price.max,
    building: type,
    must_Include: mustInclude,
  }
}


// TODO: hard coded details to be removed/replaced with common variables
const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const propertyH1 = (params, aution) => {
  // debugger
  const {areas, bedrooms, pType, type, price, extra, mustInclude, radius} = params;
   let h1 = aution === "future" ? "current auctions " : aution === "past" ? "past auctions " :  "";

  if (params?.ptypetag === 'sold' || params?.ptypetag === 'let') {
        let building = aution === "future" ? "" : aution === "past" ?  "" : "Property";
        let bedroomStr = ``;
        let actionStr = ``;
        let areaStr = ``;
        let priceStr = ``;
        let featureStr = ``;
        let radiusStr = ``;

       

        if (type) {
          building = ` ${_.get(buildingTypes, type, type)} `;
        } else {
          building = aution === "future" ? "" : aution === "past" ?  "" : `Property `;
        }
        if (!bedrooms)
            building = capitalize(building);

        // if (building)
        //     building = `with ${building}`
        //
        // h1 += toTitleCase(building);
        else if(aution === "new homes Completed"){
          actionStr = ACTION_TYPES['oldhome'] || '';
        }
        else if(aution === "past"){
          actionStr = ACTION_TYPES['auctionsold'] || '';
        }
        else if(aution === "future"){
          actionStr = ACTION_TYPES['auctionsold'] || '';
        }
        else if (params?.ptypetag && (params?.ptypetag === 'sold' || params?.ptypetag === 'let'))
            actionStr = ACTION_TYPES[`${pType + params?.ptypetag}`] || '';
        else
            actionStr = ACTION_TYPES[pType] || '';
       
         
       
        if (areas) {
          let _areas = areas;
          _areas = _areas.replaceAll("-"," ");
          _areas = decodeURIComponent(_areas);
          _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
          areaStr = _areas.replace("north","North").replace("road","Road")
        }

        if(params?.areas === "") {
          actionStr.replace("in", "")
        }

        if (bedrooms)
        building = aution === "future" ? "" : aution === "past" ?  "" : "Property";

      if (bedrooms) {
        // let bedPlus = extra.bedPlus ? '+' : '+';
        // bedroomStr = ` with ${bedrooms}${bedPlus} bedroom `;
        h1 += " with more than "+ bedrooms+ " Bedrooms ";
      }

        if ( price && (price.min || price.max) )  {
          priceStr = `at ${getPriceStr(price)}`;
        }

        // if ( mustInclude && mustInclude ) {
        //   featureStr = " with "+mustInclude.join('-and-')
        // }
        //
        if (radius) {
            radiusStr = ` within ${radius === "half" ? "1/2" : radius === "quarter" ? "1/4" : radius} miles `
        }

        if (!bedroomStr && !priceStr && areaStr === defaultArea.name) {
            if (!areaStr) {
                areaStr = defaultArea.name
            }
            h1 = `Our Collection of ${actionStr} Property in ${areaStr}`
        } else {
            h1 = `${actionStr} ${building} ${bedroomStr} in ${areaStr} ${priceStr}`
        }
        // [action] [property type] with [bedroom] in [address] at [price offered]
  } else {
      let building = "";

      if ('Commercial' === pType) { // TODO
        // Commercial Property [action] in [default search area] for Sale and Rent
        building = "Commercial ";

        if (type)
          building += ` ${type} `;
        else
          building += aution === "future" ? "" : aution === "past" ?  "" : ` Property `;

        h1 += `${building} in `;

        if (areas) {
          h1 += areas;
        }

        h1 += ` for sale and rent`;

      } else {
        // console.log("elbi",aution)
        let building = aution === "new homes Completed" ? "previous developments" : aution === "new homes Sale" ? "current developments" : aution === "future" ? "" : aution === "past" ?  "" : "Property";
       

        if (type) {
           building = `${_.get(buildingTypes, type, type)} `;
        } else {
          building = aution === "new homes Completed" ? "previous developments" : aution === "new homes Sale" ? "current developments" : aution === "future" ? "" : aution === "past" ?  "" : ` Property `;
        }

        

        if (!bedrooms)
            building = building;

        h1 += (building);
         if (params?.ptypetag && (params?.ptypetag === 'sold' || params?.ptypetag === 'let'))
            h1 += ACTION_TYPES[`${pType + params?.ptypetag}`];
            else  if(aution === "new homes Completed"){
              h1 += ACTION_TYPES['oldhome'] || '';
            }
            else if(aution === "past"){
              h1 += ACTION_TYPES['auctionsold'] || '';
            }
            else if(aution === "future"){
              h1 += ACTION_TYPES['auctionsold'] || '';
            }
        else
            h1 += ACTION_TYPES[pType];
            
           
    
           
        if(params?.areas === "") {
          h1 = h1.replace("in", "")
        }

        if (areas) {
          let _areas = areas;
          _areas = _areas.replaceAll("-"," ");
          _areas = decodeURIComponent(_areas);
          _areas = extra.areasTitleCase ? toTitleCase(_areas) : _areas;
          h1 += _areas.replace("north","North").replace("road","Road")
        }

        if (bedrooms)
        building = aution === "new homes Completed" ? "previous developments" : aution === "new homes Sale" ? "current developments" : aution === "future" ? "" : aution === "past" ?  "" :  "Property";

      if (bedrooms) {
        // let bedPlus = extra.bedPlus ? '+' : '+';
        h1 += " with more than "+ bedrooms+ " Bedrooms ";
      }
        if ( price && (price.min || price.max) )  {
          h1 += getPriceStr(price);
        }

        if ( mustInclude && mustInclude ) {
          h1 += " with "+mustInclude.join('-and-')
        }

        if (radius) {
            h1 += ` within ${radius === "half" ? "1/2" : radius === "quarter" ? "1/4" : radius} miles `
        }
      }
  }

  return h1;
}

export const handleBackToListing = (props) => {
    // console.log('click back btn');
    if (window.history.length) {
        window.history.go(-1);
        return;
    }
    let statusStr = 'for-sale';
    let areaStr = `in-${defaultArea.slug}`;
    if (props?.search_type === 'lettings')
        statusStr = 'to-rent';
    if (props?.area)
        areaStr = `in-${props.area}`;

    window.location.pathname = `property-${statusStr}/${areaStr}`;

}

const expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
const regex = new RegExp(expression);

export const isExternalUrl = (str) => {
    return (str.match(regex))
}
