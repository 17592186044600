import React, { useState, useEffect } from "react"
import { Col, Row, Tab, Tabs, Form, InputGroup, Container } from "react-bootstrap"
import { navigate } from "@reach/router"
import { navigate as gatsbyNavigate } from "gatsby";
import Select from 'react-select';
import { radiusOptions } from "../SearchResult/filter/constant"
import "./styles/_index.scss"
import SearchResultInputBox from "../../components/predictive-search/SearchResultBox"
import { PAGE_AUCTION_CURRENT } from "@components/common/site/constants";
import { defaultArea } from "@constants";
const SearchOverlay = (props) => {
    const [reload, setReload] = useState(false);
    const [areaVal, setAreaVal] = useState('');
    const [tab, settab] = useState('sales')
    useEffect(() => {
		let a1 = window?.location.pathname.indexOf("/property/for-sale") > -1
		let a2 = window?.location.pathname.indexOf("/property/to-rent") > -1
		let a3 = window?.location.pathname.indexOf("/new-homes/for-sale") > -1
		if (a1 || a2 || a3) {
			setReload(true);
		}
	}, [tab])
    const pageurl = typeof window !== 'undefined' ? window.location.href : ''
    // Dropdown react select styles
    const customStyles = {
        control: (styles, state) => {
          return {
            ...styles,
            borderColor: state.menuIsOpen ? "transparent" : "#DCDDDE",
            boxShadow: state.menuIsOpen
              ? "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 7px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16)"
              : "none",
            height: "48px",
            borderRadius: state.menuIsOpen
              ? `8px 8px 0 0`
              : '',
            "&:hover": {
              borderColor: state.menuIsOpen ? "transparent" : "#DCDDDE",
            },
          }
        },
        indicatorSeparator: () => ({
          display: "none",
        }),
        valueContainer: styles => ({
          ...styles,
          paddingLeft: "16px",
          fontSize: "14px",
        }),
        menu: styles => ({
          ...styles,
          margin: "0",
          padding: 0,
          border: 0,
          borderRadius: `0 0 8px 8px`,
          boxShadow:
            "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 0px 7px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.16)",
        }),
        menuList: styles => ({
          ...styles,
          margin: "0",
          borderBottom: "1px solid #F5F5F5",
        }),
        placeholder: styles => ({
          ...styles,
          fontSize: "inherit",
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
          ...styles,
          color: isSelected ? "#fff" : "#393939",
          backgroundColor: isSelected ? "#026178" : "#fff",      
          fontSize: "inherit",
          lineHeight: "20px",
          padding: "8px 20px",
          borderTop: "1px solid #F5F5F5",
          WebkitTapHighlightColor: "#fff",
          ":hover": {
            backgroundColor: "#ffcf14",
            // opacity: 0.7,
          },
        }),
      }
    // Dropdown react select styles

    const [areaSalesVal, setAreaSalesVal] = useState('');
    const [radiusSalesVal, setRadiusSalesVal] = useState('');

    const [areaLetVal, setAreaLetVal] = useState('');
    const [radiusLetVal, setRadiusLetVal] = useState('');

    const [areaAucVal, setAreaAucVal] = useState('');
    const [radiusAucVal, setRadiusAucVal] = useState('');
  
    // Submit search
    const submitSearchSales = (e) => {
        var searchFilterResultsSale = "";

        if(isSelected) {
            if(radiusSalesVal) {
                searchFilterResultsSale +="/in-"+areaSalesVal?.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase()+"/radius-"+radiusSalesVal+"-miles/"
            } else {
                searchFilterResultsSale +="/in-"+areaSalesVal?.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase()
            }
        }
        else {
            if(areaSalesVal) {
                searchFilterResultsSale +="/in-"+areaSalesVal?.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase()
            }

        }

        props.setShowOverlay(false);
        if(reload){
			window.location.href = "/property/for-sale"+searchFilterResultsSale;
		}else{
        navigate("/property/for-sale"+searchFilterResultsSale);
        }
    }

    const submitSearchLet = (e) => {
        var searchFilterResultsLet = "";

        if(isSelected) {
            if(radiusLetVal) {
                searchFilterResultsLet +="/in-"+areaLetVal?.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase()+"/radius-"+radiusLetVal+"-miles/"
            } else {
                searchFilterResultsLet +="/in-"+areaLetVal?.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase()
            }
        }
        else {
            if(areaLetVal){
                searchFilterResultsLet +="/in-"+areaLetVal?.replace(/ /g, "-").replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').toLowerCase()
            }
        }

       

        props.setShowOverlay(false);
        if(reload){
			window.location.href = "/property/to-rent"+searchFilterResultsLet;
		}else{
        navigate("/property/to-rent"+searchFilterResultsLet);
        }
    }

    const submitSearchAuc = (e) => {
        var searchFilterResultsAuc = "";

        if(areaAucVal) {
            if(areaAucVal === "") {
                searchFilterResultsAuc +="/in-wiltshire"
            } else {
                searchFilterResultsAuc +="/in-"+areaAucVal
            }
        }

        if(radiusAucVal) {
            searchFilterResultsAuc +="radius-"+radiusAucVal+"-miles"
        }

        props.setShowOverlay(false);
        navigate("/property-auctions/for-sale"+searchFilterResultsAuc);
    }
    // Submit search

    const [isSelected, setSelected] = useState(false);
	const tabChange = (key) => {
        if(key === "auctions"){
            navigate(PAGE_AUCTION_CURRENT.alias)
        }
        else if(key === "sales") {
            settab(key)
            setSelected(false)
            setAreaLetVal("")
            setRadiusLetVal("")
        }
        else if(key === "lettings"){
            settab(key)
            setSelected(false)
            setAreaSalesVal("")
            setRadiusSalesVal("")
        }
		
	}
    return (
        <div className="search-overlay-content-wrapper w-100">
            <Container>
                <Row className="d-flex justify-content-center">
                    <Col lg={8}>
                        <h1 className="search-heading">start your property search</h1>
                        <div className="search-text">Select the service you need to start your search</div>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    <Col lg={8} className="street-col">
                        <Tabs
                            defaultActiveKey="sales"
                            className="search-overlay-tab"
                            onSelect={(e) => tabChange(e)}
                        >
                            <Tab eventKey="sales" title="Sales">
                                <div className="d-md-flex justify-content-start">
                                    <InputGroup className="search-overlay-group">
                                    <div className="street-field">
                                    <SearchResultInputBox
              value={areaSalesVal.replaceAll(' ', '-') !== defaultArea.slug ? areaSalesVal : ''}
              setValue={setAreaSalesVal}
              placeHolder={`Street, area or postcode`}
              isSelected={isSelected}
              setSelected={setSelected}
              // areaList={areaList}
              // setList={setAreaList}
            />
                                                    <InputGroup.Text className="right-radius-overlay">
                                            <Select options={radiusOptions} values={""} isSearchable={false}
                                                classNamePrefix={"select-opt"}
                                                className="search-select-control"
                                                placeholder={radiusSalesVal ? radiusSalesVal : "Radius"}
                                                onChange={e => setRadiusSalesVal(e.value)}
                                                styles={customStyles}
                                                isDisabled={!areaSalesVal && !isSelected}
                                                components={{ DropdownIndicator:() => <i className="icon-2 icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                            />
                                        </InputGroup.Text>
            </div>
                                        
                                    </InputGroup>
                                    <a href="javascript:void(0)" onClick={submitSearchSales} className="search-btn btn btn--blue d-flex align-items-center justify-content-center">
                                        <i className="icon icon--search"></i> <span className="d-md-none">Submit</span>
                                    </a>
                                </div>
                            </Tab>
                            <Tab eventKey="lettings" title="Lettings">
                                <div className="d-md-flex justify-content-start">
                                    <InputGroup className="search-overlay-group">
                                    <div className="street-field">
                                    <SearchResultInputBox
              value={areaLetVal.replaceAll(' ', '-') !== defaultArea.slug ? areaLetVal : ''}
              setValue={setAreaLetVal}
              placeHolder={`Street, area or postcode`}
              // areaList={areaList}
              // setList={setAreaList}
              isSelected={isSelected}
              setSelected={setSelected}
            />
             <InputGroup.Text className="right-radius-overlay">
                                            <Select options={radiusOptions} values={""} isSearchable={false}
                                                classNamePrefix={"select-opt"}
                                                className="search-select-control"
                                                placeholder={radiusLetVal ? radiusLetVal : "Radius"}
                                                onChange={e => setRadiusLetVal(e.value)}
                                                styles={customStyles}
                                                isDisabled={!areaLetVal && !isSelected}
                                                components={{ DropdownIndicator:() => <i className="icon-2 icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                            />
                                        </InputGroup.Text>
            </div>

                                    </InputGroup>
                                    <a href="javascript:void(0)" onClick={submitSearchLet} className="search-btn btn btn--blue d-flex align-items-center justify-content-center">
                                        <i className="icon icon--search"></i> <span className="d-md-none">Submit</span>
                                    </a>
                                </div>
                            </Tab>
                            <Tab eventKey="auctions" title="Auctions">
                                {/* <div className="d-md-flex justify-content-start">
                                    <InputGroup className="search-overlay-group">
                                    <div className="street-field">
                                    <SearchResultInputBox
              value={areaAucVal.replaceAll(' ', '-') !== defaultArea.slug ? areaAucVal : ''}
              setValue={setAreaAucVal}
              placeHolder={`Street, area or postcode`}
              // areaList={areaList}
              // setList={setAreaList}
            />
             <InputGroup.Text className="right-radius-overlay">
                                            <Select options={radiusOptions} values={""} isSearchable={false}
                                                classNamePrefix={"select-opt"}
                                                className="search-select-control"
                                                placeholder={radiusAucVal ? radiusAucVal : "Radius"}
                                                onChange={e => setRadiusAucVal(e.value)}
                                                styles={customStyles}
                                                isDisabled={!areaAucVal}
                                                components={{ DropdownIndicator:() => <i className="icon-2 icon-select-dropdown"></i>, IndicatorSeparator:() => null }}
                                            />
                                        </InputGroup.Text>
            </div>
                                        
                                    </InputGroup>
                                    <a href="javascript:void(0)" onClick={submitSearchAuc} className="search-btn btn btn--blue d-flex align-items-center justify-content-center">
                                        <i className="icon icon--search"></i> <span className="d-md-none">Submit</span>
                                    </a>
                                </div> */}
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SearchOverlay