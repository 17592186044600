import React from "react";
import { isEmpty, capitalize, join, startCase, toLower, forEach } from "lodash";
import { useStaticQuery, graphql } from "gatsby";
import scrollIntoView from 'scroll-into-view-if-needed'
import * as qs from "query-string"
/** Get the menu page url */
const defaultArea = "South Hertfordshire";
/**Start with */
export const startsWith = (str, word) => {
  return str?.lastIndexOf(word, 0) === 0;
}

/**Eng with */
export const endsWith = (str, word) => {
  return str?.slice(-1) === word;
}

/** GTM Event Trigger*/
export const gtmEvent = ({ event = "formSubmit", formType, formName }) => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'formSubmit',
      'formType': `form-${formType}`,
      'formId': `form-${formType}`,
      'formName': formName,
      'formLabel': formName
    });
  }
}



/** currency format conversion */
export const currencyFormat = (num, currency = '£', withSpace = true, max) => {
  var filterNum = filterNumber(num);
  if (!filterNum) {
    return ``
  }
  if (withSpace) {
    return `${currency} ${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
  } 
  else if(max) {
    return ` - ${currency}${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
  }
  else {
    return `${currency}${new Intl.NumberFormat("en-US", {}).format(filterNum)}`
  }

}


/** Get number only */
export const filterNumber = (str) => {
  if (!str) return 0
  str = str.toString()
  return parseInt(str.replace(/[^\d.]/g, ""), 10)
}

/** Get number format with out currency*/
export const numberFormat = num => {
  if (!num) return 0
  return new Intl.NumberFormat("en-US", {}).format(num?.toFixed())
}

/** Get url params*/
export const getUrlParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlSearchParams.entries());
}

/** string include spl character*/
export const isIncludeSplChar = (text) => {
  const format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  return format.test(text);
}
/** string to captilize*/
export const toCapitalize = (str) => {
  if (!isEmpty(str)) {
    return str.charAt(0).toUpperCase() + str.slice(1)
    // startCase(toLower(str.replace(/[^a-zA-Z ]/g, " ").replace(/\s+/g, ' ')));
  }
  return str;
}
/** string remove all special characters*/
export const removeSpecialChar = (val, symbol = '-') => {
  if (!isEmpty(val)) {
    return val.replace(/[^\w\s]/gi, ' ').replace(/\s\s+/g, ' ').replace(/ /gi, symbol).toLowerCase()
  }
  return val;
}

/** Get url params*/
export const squareFeetToMeter = (val) => {
  let meter = (0.09290304 * val)
  return `${Math.round(meter)}`;
}


export const isNotEmpty = (val) => {
  if (typeof val != "undefined" && val !== null && val !== "") {
    return true
  }
  return false;
}


/**Scroll To Top */
export const ScrollToTop = (ref, top = 100) => {
  scrollIntoView(ref, {
    behavior: actions =>
      actions.forEach(({ el }) => {
        el.scrollTop = top
      }),
  })
}

/**Send Mail*/
export const SendMail = (postData) => {
  window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/form`, {
    method: `POST`,
    mode: "no-cors",
    headers: {
      'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qs.stringify(postData),
  })
}

export const SendMailFriend = (postData) => {
  window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/send-friend-form`, {
    method: `POST`,
    mode: "no-cors",
    headers: {
      'Access-Control-Allow-Origin': '*',
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: qs.stringify(postData),
  })
}


/**Form Validation*/
export const FormValidation = (fields, validationFields) => {
  var error = {}
  var isValid = true;
  forEach(validationFields, (field, k) => {
    if (fields[field] === null || fields[field] === "") {
      error[field] = true;
      isValid = false
    } else {
      if (field === "email" && !(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(fields[field]))) {
        error[field] = true;
        isValid = false
      } else if (field === "telephone" && fields[field].length <= 8) {
        error[field] = true;
        isValid = false
      } else {
        error[field] = false;
      }
    }
  })
  if (!isValid) {
    return { status: false, errors: error };
  }
  return { status: true, errors: {} };
}



// Create URL
export const searchStateToUrl = (index_name, state, location) => {
  debugger
  const isDefaultRoute =
    !state.query &&
    !state.sortBy &&
    state.page === 1 &&
    state.menu &&
    state.range &&
    !state.range.price &&
    !state.range.bedroom

  if (isDefaultRoute) {
    return ""
  }

  var areaPath = ""
  var bedroomPath = ""
  var pricePath = ""
  var pagePath = ""
  var propertypath = ""
  var sortPath = ""

  if (state.query) {
    if (state.query) {
      areaPath = "in-" + encodeURIComponent(decodeURIComponent(removeSpecialChar(state.query))) + "/"
    }
  }

  // Property path
  if (state.menu) {
    if (state.menu["building"]) {
      propertypath = "type-" + state.menu["building"].replace(/ /g, '-') + "/"
    }
  }
  // Property path

  // Bedrooms & Price range path
  if (state.range) {
    // Bedrooms 
    if (state.range.bedroom) {
      // console.log('search', state.range.bedroom)
      if (state.range.bedroom.min) {
        bedroomPath = state.range.bedroom.min + "-and-more-bedrooms/"
      }
    }

    // Price
    if (state.range.price) {
      if (state.range.price.min && state.range.price.max) {
        pricePath = "between-" + state.range.price.min + "-and-" + state.range.price.max + '/';
      } else if (state.range.price.max) {
        pricePath = "below-" + state.range.price.max + '/';
      } else if (state.range.price.min) {
        pricePath = "above-" + state.range.price.min + '/';
      }
    }
  }
  // Bedrooms & Price range path

  // Sort path
  if (state.sortBy) {
    if (state.sortBy === index_name + "_price_asc") {
      sortPath = "sortby-price-asc/";
    }
    if (state.sortBy === index_name + "_price_desc") {
      sortPath = "sortby-price-desc/";
    }
  }
  // Sort path

  // Page path
  if (state.page && state.page !== 1) {
    pagePath = `page-${state.page}/`
  }
  // Page path 


  let myUrl = `${areaPath}${propertypath}${bedroomPath}${pricePath}${pagePath}${sortPath}`;


  if ("/" !== myUrl.substr(myUrl.length - 1))
    myUrl = myUrl.substr(0, myUrl.length - 1)

  return `${location}${myUrl}`
}
// Create URL


// URL to searchstate 
export const urlToSearchState = (index_name, location) => {
  debugger
  if (location) {
    var query = ""
    let pathUri_main = ''

    let a1 = location.pathname.indexOf("/property/for-sale") > -1
    let a2 = location.pathname.indexOf("/property/to-rent") > -1
    if (a1 == true) {
      pathUri_main = location.pathname.split("/property/for-sale")
    }
    else if (a2 == true) {
      pathUri_main = location.pathname.split("/property/to-rent")
    } 
    else {
      pathUri_main = location.pathname.split(location)
    }

    var areaVal = ""
    var bedVal = 10
    var minpriceVal = ""
    var maxpriceVal = ""
    var pageVal = ""
    var propertyval = ""
    var sortVal = ""
    var sortVal_filt = ""
    

    if (pathUri_main[1]) {
      // following could be regexp
      let pathUri = pathUri_main[1].split("/")
      //lets loop
      for (let vi = 1; vi <= pathUri.length; vi++) {
        // check for area
        if (typeof pathUri[vi] === "undefined") {
          continue
        }

        // Property type
        if (pathUri[vi].indexOf("type-") >= 0) {
          propertyval = pathUri[vi].replace("type-", "")
        }

        // Area
        if (pathUri[vi].indexOf("in-") >= 0) {
          areaVal = removeSpecialChar(pathUri[vi].replace("in-", ""), " ")
        }
        // Price
        if (
          pathUri[vi].indexOf("between-") >= 0 ||
          pathUri[vi].indexOf("above-") >= 0 ||
          pathUri[vi].indexOf("below-") >= 0
        ) {
          let priceFilt1 = pathUri[vi].split("above-")
          if (priceFilt1[1]) {
            minpriceVal = priceFilt1[1]
          }
          let priceFilt2 = pathUri[vi].split("below-")
          if (priceFilt2[1]) {
            maxpriceVal = priceFilt2[1]
          }
          let priceFilt3 = pathUri[vi].split("between-")
          if (priceFilt3[1]) {
            let priceFilt4 = priceFilt3[1].split("-and-")
            minpriceVal = priceFilt4[0]
            maxpriceVal = priceFilt4[1]
          }
        }

        // Bedrooms
        if (pathUri[vi].indexOf("-and-more-") >= 0) {
          bedVal = pathUri[vi].replace("-and-more-bedrooms", "")
        }

        if (pathUri[vi].indexOf("studio") >= 0) {
          // its studio bedroom
          bedVal = 0
        }

        // Sort by
        if (pathUri[vi].indexOf("sortby-") >= 0) {
          sortVal_filt = pathUri[vi].replace("sortby-", "")

          if (sortVal_filt === "price-asc") {
            sortVal = index_name + "_price_asc"
          }

          if (sortVal_filt === "price-desc") {
            sortVal = index_name + "_price_desc"
          }

          if (sortVal_filt === "most-recent") {
            sortVal = index_name + "_most_recent"
          }
        }

        // Page
        if (pathUri[vi].indexOf("page") >= 0) {
          pageVal = pathUri[vi].replace("page-", "")
        }
      }
    }
  }

  // Property type
  if (propertyval) {
    query += `&menu[building]=${propertyval}`
  }

  // Area
  if (areaVal) {
    areaVal = areaVal.toLowerCase();
    query += `&query=${areaVal}`
  }

  // Price
  if (minpriceVal) {
    query += `&range[price][min]=${minpriceVal}`;
  }

  if (maxpriceVal) {
    query += `&range[price][max]=${maxpriceVal}`;
  }

  // Bedrooms
  if (bedVal == 0) {
    query += `&range[bedroom][min]=0` + 0
  } else if (bedVal < 10) {
    query += `&range[bedroom][min]=${bedVal}`
  }

  // Page
  if (pageVal) {
    query += `&page=${pageVal}`
  }

  // Sort by
  if (sortVal) {
    query += `&sortBy=${sortVal}`
  }

  return qs.parse(query)
}
// URL to searchstate

export const getPriceStr = (min, max) => {
  let priceStr = '';
  if (min && max) {
    priceStr = `between ${currencyFormat(min)} and ${currencyFormat(max)}`;
  } else if (max) {
    priceStr = `below ${currencyFormat(max)}`;
  } else if (min) {
    priceStr = `above ${currencyFormat(min)}`;
  }
  return priceStr;
}

export const propertySearchParams = (search) => {
  var actionIn = "for sale";
  var type = "sales"
  if (search.path === "/property/to-rent/") {
    actionIn = "to rent"
    type = "lettings"
  } else if (search.path === "/property/new-homes/for-sale/") {
    type = "newhomes"
  }

  return {
    propertyType: (search?.searchState?.menu?.building) ? search?.searchState?.menu?.building : null,
    defaultArea: defaultArea,
    search_type: type,
    areas: (search?.searchState?.query) ? search?.searchState?.query : defaultArea,
    action: actionIn,
    minPrice: (search?.searchState?.range?.price?.min) ? search?.searchState?.range?.price?.min : null,
    maxPrice: (search?.searchState?.range?.price?.max) ? search?.searchState?.range?.price?.max : null,
    bedrooms: (search?.searchState?.range?.bedroom?.min) ? search?.searchState?.range?.bedroom?.min : null
  }
}

/**Property Intro */
export const propertyIntro = ({ type, propertyType, areas, minPrice, maxPrice, bedrooms, action, introCopy = false, isMetaTitle = false }) => {
  let intro = ["Property"];
  if (introCopy) {
    intro = ["property"];
    if (!areas) {
      areas = "South Hertfordshire";
    }
  }
  if (isMetaTitle) {
    intro = ["Luxury property"];
    if (!areas) {
      areas = "South Hertfordshire";
    }
  }
  if (type === "newhomes") {
    intro = ["New Homes"];
  }
  if (propertyType) {
    intro = [];
    intro.push(toCapitalize(propertyType));
  }
  if (action) {
    intro.push(action)
  }
  if (areas) {
    intro.push(`in ${toCapitalize(areas)}`)
  }
  if (isMetaTitle) {
    intro.push(`| ${toCapitalize(areas)} homes`)
    if (action) {
      intro.push((action))
    }
  }

  // if (introCopy || isMetaTitle) {
    // return join(intro, ' ');
  // }

  if (bedrooms) {
    if (bedrooms > 1) {
      intro.push(`with more than ${bedrooms} bedrooms`)
    } else {
      intro.push(`with more than ${bedrooms} bedroom`)
    }
  }

  if (minPrice || maxPrice) {
    intro.push(getPriceStr(minPrice, maxPrice));
  }
  return join(intro, ' ');
}
