import {
    FacebookIcon,
    FacebookEmptyIcon,
    InstagramIcon,
    TwitterIcon,
    TwitterEmptyIcon,
    LinkedInIcon,
    LinkedInEmptyIcon,
    TagIcon,
    StarberryIcon
} from "../../components/icons/icons"

export const DefaultIcons = {
    "facebookIcon": FacebookIcon,
    "instagramIcon": InstagramIcon,
    "twitterIcon": TwitterIcon,
    "linkedInIcon": LinkedInIcon,
    "tagIcon" : TagIcon,
    "starberryIcon" : StarberryIcon
}