import React, { useState,useEffect } from "react";
import { Row, Col } from 'react-bootstrap';
const PopularSearchDynamic = (props) => {
  //Get values from refine search
	const [ptype,setPtype] = useState(false)
  const [searchbed,setsearchbed] = useState(false)
 

  var searchaction = ''
  var URL = ''
  var ptypeURL = ''
  var bedURL = ''
  var bedtypeText= ''
  var area = 'Wiltshire'
  var typeText = 'Property'
  var searcharea = ''
  var searchareaText=''
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }  
  if(props.searchtype == 'sales') {
    searchaction = 'for Sale'
    URL          = '/property/for-sale/'
  }
  if(props.searchtype == 'lettings') {
    searchaction = 'to Rent'
    URL          = '/property/to-rent/'
  }
  if(props.searchPtype) {
    ptypeURL   = '/type-'+props.searchPtype
    typeText     = capitalizeFirstLetter(props.searchPtype.replace(/-/g, ' '))
  }
  if(props.searchBedroomfield) {
    bedtypeText = props.searchBedroomfield+' bedroom '+typeText
    bedURL   = '/'+props.searchBedroomfield+'-and-more-bedrooms'
  }
  //console.log("$$$$", props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas)
  if(props.Searcharea && props.Searcharea.areas !='undefined' && props.Searcharea.areas) {
    area = props.Searcharea.areas
    area = capitalizeFirstLetter(props.Searcharea.areas.replace(/-/g, ' '))
  }
  return (<React.Fragment>
    {/* {JSON.stringify(props.Searcharea)} */}
    {
      props.searchBedroomfield && (typeText !== 'Property') ?
          <>
         <Col lg className="wysiwyg-block">
      
      
      <a className="footer_scroll_top" href={`${URL}in-bath${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Bath</a>
      <a className="footer_scroll_top" href={`${URL}in-swindon${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Swindon</a>
      <a className="footer_scroll_top" href={`${URL}in-tetbury${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Tetbury</a>
      <a className="footer_scroll_top" href={`${URL}in-royal-wootton-bassett${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Royal Wootton Bassett</a>
      <a className="footer_scroll_top" href={`${URL}in-cirencester${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Cirencester</a>
      
      
      </Col>
      <Col lg className="wysiwyg-block">
        
      
        <a className="footer_scroll_top" href={`${URL}in-devizes${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Devizes</a>
        <a className="footer_scroll_top" href={`${URL}in-chippenham${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Chippenham</a>
        <a className="footer_scroll_top" href={`${URL}in-corsham${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Corsham</a>
        <a className="footer_scroll_top" href={`${URL}in-malmesbury${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Malmesbury</a>
        <a className="footer_scroll_top" href={`${URL}in-calne${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Calne</a>
      
      
      </Col>
        <Col lg className="wysiwyg-block">
          
        {props.searchBedroomfield !== "1" &&      
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/1-and-more-bedrooms/`}>1 bedroom Property {searchaction} in {area}</a>
      }
      {props.searchBedroomfield !== "2" &&      
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/2-and-more-bedrooms/`}>2 bedroom Property {searchaction} in {area}</a>
      }
      {props.searchBedroomfield !== "3" &&      
          <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/3-and-more-bedrooms/`}>3 bedroom Property {searchaction} in {area}</a>
      }
      {props.searchBedroomfield !== "4" &&      
          <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/4-and-more-bedrooms/`}>4 bedroom Property {searchaction} in {area}</a>
      }
      {props.searchBedroomfield !== "5" &&      
          <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/5-and-more-bedrooms/`}>5 bedroom Property {searchaction} in {area}</a>
      }
      {props.searchBedroomfield !== "6" &&      
          <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/6-and-more-bedrooms/`}>6 bedroom Property {searchaction} in {area}</a>
      }
        
        </Col> 
       
      
         
          <Col lg className="wysiwyg-block">
          
          
          <a href={`/about/our-branches/`}>Estate agents in {area}</a>
          <a href={`/property-landlords/property-maintenance/`}>Property maintenance in {area}</a>
          <a href={`/property-auctions/for-sale/`}>Property auctions in {area}</a>
          <a href={`/sell-your-property/property-valuation-in-wiltshire/`}>Property valuation in {area}</a>
          <a href={`/about/estate-agent-jobs-in-wiltshire/`}>Estate agent jobs in {area}</a>
          
          
          
          </Col> 
        </>  
    :

    props.searchBedroomfield && (props.Searcharea?.price?.min || props.Searcharea?.price?.max) ?
    <>
     <Col lg className="wysiwyg-block">
  

  <a className="footer_scroll_top" href={`${URL}in-devizes${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Devizes</a>
  <a className="footer_scroll_top" href={`${URL}in-chippenham${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Chippenham</a>
  <a className="footer_scroll_top" href={`${URL}in-corsham${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Corsham</a>
  <a className="footer_scroll_top" href={`${URL}in-malmesbury${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Malmesbury</a>
  <a className="footer_scroll_top" href={`${URL}in-calne${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Calne</a>


</Col>
     <Col lg className="wysiwyg-block">
          
          {props.searchBedroomfield !== "1" &&      
          <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/1-and-more-bedrooms/`}>1 bedroom Property {searchaction} in {area}</a>
        }
        {props.searchBedroomfield !== "2" &&      
          <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/2-and-more-bedrooms/`}>2 bedroom Property {searchaction} in {area}</a>
        }
        {props.searchBedroomfield !== "3" &&      
            <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/3-and-more-bedrooms/`}>3 bedroom Property {searchaction} in {area}</a>
        }
        {props.searchBedroomfield !== "4" &&      
            <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/4-and-more-bedrooms/`}>4 bedroom Property {searchaction} in {area}</a>
        }
        {props.searchBedroomfield !== "5" &&      
            <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/5-and-more-bedrooms/`}>5 bedroom Property {searchaction} in {area}</a>
        }
        {props.searchBedroomfield !== "6" &&      
            <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/6-and-more-bedrooms/`}>6 bedroom Property {searchaction} in {area}</a>
        }
          
          </Col> 
     <Col lg className="wysiwyg-block">    
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-apartment/`}>Apartments {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-bungalow/`}>Bungalows {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-house/`}>Houses {searchaction} in {area}</a>
            <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-flat/`}>Flats {searchaction} in {area}</a>
            <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-barn-conversions`}>Barn Conversions {searchaction} in {area}</a>
            
          
          
          </Col>  

       
        <Col lg className="wysiwyg-block">
        
        
        <a href={`/about/our-branches/`}>Estate agents in {area}</a>
        <a href={`/property-landlords/property-maintenance/`}>Property maintenance in {area}</a>
        <a href={`/property-auctions/for-sale/`}>Property auctions in {area}</a>
        <a href={`/sell-your-property/property-valuation-in-wiltshire/`}>Property valuation in {area}</a>
        <a href={`/about/estate-agent-jobs-in-wiltshire/`}>Estate agent jobs in {area}</a>
        
        
        
        </Col> 
    </>
    :
    (typeText !== 'Property') || ((typeText !== 'Property') && (props.Searcharea?.price?.min || props.Searcharea?.price?.max)) ?
    <>
      <Col lg className="wysiwyg-block">
  

  <a className="footer_scroll_top" href={`${URL}in-bath${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Bath</a>
  <a className="footer_scroll_top" href={`${URL}in-swindon${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Swindon</a>
  <a className="footer_scroll_top" href={`${URL}in-tetbury${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Tetbury</a>
  <a className="footer_scroll_top" href={`${URL}in-royal-wootton-bassett${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Royal Wootton Bassett</a>
  <a className="footer_scroll_top" href={`${URL}in-cirencester${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Cirencester</a>


</Col>
<Col lg className="wysiwyg-block">
        
        
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/1-and-more-bedrooms/`}>1 bedroom Property {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/2-and-more-bedrooms/`}>2 bedroom Property {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/3-and-more-bedrooms/`}>3 bedroom Property {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/4-and-more-bedrooms/`}>4 bedroom Property {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/5-and-more-bedrooms/`}>5 bedroom Property {searchaction} in {area}</a>
        
        
        </Col> 
        <Col lg className="wysiwyg-block">    
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-apartment/`}>Apartments {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-bungalow/`}>Bungalows {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-house/`}>Houses {searchaction} in {area}</a>
            <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-flat/`}>Flats {searchaction} in {area}</a>
            <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-barn-conversions`}>Barn Conversions {searchaction} in {area}</a>
            
          
          
          </Col>  

       
        <Col lg className="wysiwyg-block">
        
        
        <a href={`/about/our-branches/`}>Estate agents in {area}</a>
        <a href={`/property-landlords/property-maintenance/`}>Property maintenance in {area}</a>
        <a href={`/property-auctions/for-sale/`}>Property auctions in {area}</a>
        <a href={`/sell-your-property/property-valuation-in-wiltshire/`}>Property valuation in {area}</a>
        <a href={`/about/estate-agent-jobs-in-wiltshire/`}>Estate agent jobs in {area}</a>
        
        
        
        </Col> 
          
    
          </>
    : (props.Searcharea?.price?.min || props.Searcharea?.price?.max) ?
<>
<Col lg className="wysiwyg-block">
            
          
            <a className="footer_scroll_top" href={`${URL}in-trowbridge${ptypeURL}/`}>{typeText} {searchaction} in Trowbridge</a>
            <a className="footer_scroll_top" href={`${URL}in-melksham${ptypeURL}/`}>{typeText} {searchaction} in Melksham</a>
            <a className="footer_scroll_top" href={`${URL}in-bath${ptypeURL}/`}>{typeText} {searchaction} in Bath</a>
            <a className="footer_scroll_top" href={`${URL}in-swindon${ptypeURL}/`}>{typeText} {searchaction} in Swindon</a>
            <a className="footer_scroll_top" href={`${URL}in-tetbury${ptypeURL}/`}>{typeText} {searchaction} in Tetbury</a>
          
          
          </Col>
          <Col lg className="wysiwyg-block">
        
        
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/1-and-more-bedrooms/`}>1 bedroom Property {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/2-and-more-bedrooms/`}>2 bedroom Property {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/3-and-more-bedrooms/`}>3 bedroom Property {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/4-and-more-bedrooms/`}>4 bedroom Property {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/5-and-more-bedrooms/`}>5 bedroom Property {searchaction} in {area}</a>
        
        
        </Col> 
        <Col lg className="wysiwyg-block">    
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-apartment/`}>Apartments {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-bungalow/`}>Bungalows {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-house/`}>Houses {searchaction} in {area}</a>
            <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-flat/`}>Flats {searchaction} in {area}</a>
            <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-barn-conversions`}>Barn Conversions {searchaction} in {area}</a>
            
          
          
          </Col>  

       
        <Col lg className="wysiwyg-block">
        
        
        <a href={`/about/our-branches/`}>Estate agents in {area}</a>
        <a href={`/property-landlords/property-maintenance/`}>Property maintenance in {area}</a>
        <a href={`/property-auctions/for-sale/`}>Property auctions in {area}</a>
        <a href={`/sell-your-property/property-valuation-in-wiltshire/`}>Property valuation in {area}</a>
        <a href={`/about/estate-agent-jobs-in-wiltshire/`}>Estate agent jobs in {area}</a>
        
        
        
        </Col> 
      
</>


: 
(typeText !== 'Property') ?
<>
  <Col lg className="wysiwyg-block">


<a className="footer_scroll_top" href={`${URL}in-bath${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Bath</a>
<a className="footer_scroll_top" href={`${URL}in-swindon${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Swindon</a>
<a className="footer_scroll_top" href={`${URL}in-tetbury${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Tetbury</a>
<a className="footer_scroll_top" href={`${URL}in-royal-wootton-bassett${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Royal Wootton Bassett</a>
<a className="footer_scroll_top" href={`${URL}in-cirencester${ptypeURL}${bedURL}/`}>{typeText} {searchaction} in Cirencester</a>


</Col>
<Col lg className="wysiwyg-block">
    
    
    <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/1-and-more-bedrooms/`}>1 bedroom Property {searchaction} in {area}</a>
    <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/2-and-more-bedrooms/`}>2 bedroom Property {searchaction} in {area}</a>
    <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/3-and-more-bedrooms/`}>3 bedroom Property {searchaction} in {area}</a>
    <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/4-and-more-bedrooms/`}>4 bedroom Property {searchaction} in {area}</a>
    <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/5-and-more-bedrooms/`}>5 bedroom Property {searchaction} in {area}</a>
    
    
    </Col> 
    <Col lg className="wysiwyg-block">    
    <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-apartment/`}>Apartments {searchaction} in {area}</a>
    <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-bungalow/`}>Bungalows {searchaction} in {area}</a>
    <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-house/`}>Houses {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-flat/`}>Flats {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-barn-conversions`}>Barn Conversions {searchaction} in {area}</a>
        
      
      
      </Col>  

   
    <Col lg className="wysiwyg-block">
    
    
    <a href={`/about/our-branches/`}>Estate agents in {area}</a>
    <a href={`/property-landlords/property-maintenance/`}>Property maintenance in {area}</a>
    <a href={`/property-auctions/for-sale/`}>Property auctions in {area}</a>
    <a href={`/sell-your-property/property-valuation-in-wiltshire/`}>Property valuation in {area}</a>
    <a href={`/about/estate-agent-jobs-in-wiltshire/`}>Estate agent jobs in {area}</a>
    
    
    
    </Col> 
      

      </>
      
     :
    props.searchBedroomfield ?
        <>
       <Col lg className="wysiwyg-block">
  

  <a className="footer_scroll_top" href={`${URL}in-trowbridge${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Trowbridge</a>
  <a className="footer_scroll_top" href={`${URL}in-melksham${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Melksham</a>
  <a className="footer_scroll_top" href={`${URL}in-bath${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Bath</a>
  <a className="footer_scroll_top" href={`${URL}in-swindon${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Swindon</a>
  <a className="footer_scroll_top" href={`${URL}in-tetbury${ptypeURL}${bedURL}/`}>{bedtypeText} {searchaction} in Tetbury</a>


</Col>

      <Col lg className="wysiwyg-block">
      {props.searchBedroomfield !== "1" &&      
      <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/1-and-more-bedrooms/`}>1 bedroom Property {searchaction} in {area}</a>
  }
  {props.searchBedroomfield !== "2" &&      
      <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/2-and-more-bedrooms/`}>2 bedroom Property {searchaction} in {area}</a>
}
{props.searchBedroomfield !== "3" &&      
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/3-and-more-bedrooms/`}>3 bedroom Property {searchaction} in {area}</a>
}
{props.searchBedroomfield !== "4" &&      
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/4-and-more-bedrooms/`}>4 bedroom Property {searchaction} in {area}</a>
}
{props.searchBedroomfield !== "5" &&      
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/5-and-more-bedrooms/`}>5 bedroom Property {searchaction} in {area}</a>
}
{props.searchBedroomfield !== "6" &&      
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/6-and-more-bedrooms/`}>6 bedroom Property {searchaction} in {area}</a>
}
      
      </Col> 
      <Col lg className="wysiwyg-block">    
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-apartment/`}>Apartments {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-bungalow/`}>Bungalows {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-house/`}>Houses {searchaction} in {area}</a>
            <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-flat/`}>Flats {searchaction} in {area}</a>
            <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-barn-conversions`}>Barn Conversions {searchaction} in {area}</a>
            
          
          
          </Col>  

       
        <Col lg className="wysiwyg-block">
        
        
        <a href={`/about/our-branches/`}>Estate agents in {area}</a>
        <a href={`/property-landlords/property-maintenance/`}>Property maintenance in {area}</a>
        <a href={`/property-auctions/for-sale/`}>Property auctions in {area}</a>
        <a href={`/sell-your-property/property-valuation-in-wiltshire/`}>Property valuation in {area}</a>
        <a href={`/about/estate-agent-jobs-in-wiltshire/`}>Estate agent jobs in {area}</a>
        
        
        
        </Col> 
      </>  
:
<>
<Col lg className="wysiwyg-block">
            <a className="footer_scroll_top" href={`${URL}in-devizes${ptypeURL}/`}>{typeText} {searchaction} in Devizes</a>
            <a className="footer_scroll_top" href={`${URL}in-chippenham${ptypeURL}/`}>{typeText} {searchaction} in Chippenham</a>
            <a className="footer_scroll_top" href={`${URL}in-xorsham${ptypeURL}/`}>{typeText} {searchaction} in Corsham</a>
            <a className="footer_scroll_top" href={`${URL}in-malmesbury${ptypeURL}/`}>{typeText} {searchaction} in Malmesbury</a>
            <a className="footer_scroll_top" href={`${URL}in-calne${ptypeURL}/`}>{typeText} {searchaction} in Calne</a>
          </Col>
          <Col lg className="wysiwyg-block">
        
        
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/1-and-more-bedrooms/`}>1 bedroom Property {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/2-and-more-bedrooms/`}>2 bedroom Property {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/3-and-more-bedrooms/`}>3 bedroom Property {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/4-and-more-bedrooms/`}>4 bedroom Property {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/5-and-more-bedrooms/`}>5 bedroom Property {searchaction} in {area}</a>
        
        
        </Col> 
        <Col lg className="wysiwyg-block">    
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-apartment/`}>Apartments {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-bungalow/`}>Bungalows {searchaction} in {area}</a>
        <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-house/`}>Houses {searchaction} in {area}</a>
            <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-flat/`}>Flats {searchaction} in {area}</a>
            <a className="footer_scroll_top" href={`${URL}in-${area?.toLowerCase()?.replace(/\s/g , '-')}/type-barn-conversions`}>Barn Conversions {searchaction} in {area}</a>
            
          
          
          </Col>  

       
        <Col lg className="wysiwyg-block">
        
        
        <a href={`/about/our-branches/`}>Estate agents in {area}</a>
        <a href={`/property-landlords/property-maintenance/`}>Property maintenance in {area}</a>
        <a href={`/property-auctions/for-sale/`}>Property auctions in {area}</a>
        <a href={`/sell-your-property/property-valuation-in-wiltshire/`}>Property valuation in {area}</a>
        <a href={`/about/estate-agent-jobs-in-wiltshire/`}>Estate agent jobs in {area}</a>
        
        
        
        </Col> 
      </>  
}


  </React.Fragment>)

}

export default PopularSearchDynamic;
