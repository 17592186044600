import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { isEmpty, forEach } from "lodash";
import { useStaticQuery, graphql } from "gatsby"
// import {
//   Commercial_Sale_Page_Url,
//   Commercial_Rent_Page_Url,
//   NewHomeSale_Page_Url,
//   ToRent_Page_Url,
//   ForSale_Page_Url,
//   AuctionSale_Page_Url,
//   URLS,
// } from "@Components/common/site/constants"
/**
 * Assets
 */
/**Start with */
export const startsWith = (str, word) => {
  return str?.lastIndexOf(word, 0) === 0;
}

const GetURL = props => {
  const data = useStaticQuery(graphql`
    query {
      glstrapi {
        allMenus(publicationState: LIVE) {
          Alias
          URL
          Label
          Main_Parent {
            Alias
            URL
          }
          Sub_Parent {
            Alias
            URL
          }
          Link_Type
        }
      }
    }
  `)

  const PageURL = data.glstrapi?.allMenus
    .filter(item => item?.Alias === props?.alias)
    .pop()

  var URL = PageURL?.URL

  if (PageURL?.Main_Parent != null && PageURL?.Sub_Parent == null) {
    URL = PageURL?.Main_Parent?.URL + "/" + URL
  } else if (PageURL?.Main_Parent != null && PageURL?.Sub_Parent != null) {
    URL = PageURL?.Main_Parent?.URL + "/" + PageURL?.Sub_Parent?.URL + "/" + URL
  }
  const { children, ...rest } = props
  // useEffect(() => {
  //   if (typeof window !== undefined) {
  //     if (window.scrollY) {
  //       window.scroll(0, 0)
  //     }
  //   }
  // }, [])
// var manualLink = props.alias;
  if(props.customLink) {
  if (!isEmpty(props.alias)) {
    if (startsWith(props.alias, '/')) {
      return props.alias;
    }
    return `/${props.alias}`;
  }
}
  return (
    <>
      {props.customLink ? 

<>
         
<Link
onClick={props?.onClick}
  to={props?.link ? props?.link : props.alias}
  className={props.class}
>
  {props.label}
  {children}
</Link>

</>:
      PageURL?.Link_Type === "Internal" ?
        <>
         
            <Link
            onClick={props?.onClick}
              to={`/${URL}/`}
              className={props.class}
            >
              {props.label}
              {children}
            </Link>
          
        </>
        : props.Link_Type === "Internal_url" ?
        <>
          <Link onClick={props?.onClick} to={props.alias} className={props.class}>
            {props.label} {children}
          </Link>
        </>
      : props.Link_Type === "External_url" ?
        <>
          <a onClick={props?.onClick} href={props.alias} target="_blank" className={props.class}>
            {props.label} {children}
          </a>
        </>
      : PageURL?.Link_Type === "External_New_Window" ?
        <>
          <a onClick={props?.onClick}
            href={PageURL?.External_URL}
            target="_blank"
            className={props.class}
          >
            {props.label} {children}
          </a>
        </>
       :    <Link onClick={props?.onClick}
       to={`/${URL}/`}
       className={props.class}
     >
       {props.label}
       {children}
     </Link>
  }
    </>
  )
}

export default GetURL

// Specifies the default values for props:
GetURL.defaultProps = {
  label: "",
  class: "",
  arrow: "no",
  dropdown: "no",
  style: "",
  URL: "",
  icon: "",
}
