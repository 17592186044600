

export const URLS = {
  TEAM: {
    name: 'Team',
    alias: '/about/meet-our-team'
  },
  OFFICE: {
    name: 'OFFICE',
    alias: '/about/our-branches'
  },
  CAREERS: {
    name: 'CAREERS',
    alias: '/about/estate-agent-jobs-in-wiltshire'
  },
  GUIDE: {
    name: 'GUIDE',
    alias: '/about/area-guide'
  },
  NEWS: {
    name: 'NEWS',
    alias: '/latest-property-news'
  },

  VLOGS: {
    name: 'Vlogs',
    alias: '/property-vlogs'
  },
 
  REVIEWS: {
    name: 'REVIEWS',
    alias: '/about/customer-reviews'
  },
  FOR_SALE: {
    name: 'Sale',
    alias: '/property/for-sale'
  },
  TO_RENT: {
    name: 'Rent',
    alias: '/property/to-rent'
  },
  AUCTION_CURRENT: {
    name: 'Auctions',
    alias: '/property-auctions/for-sale'
  },
  AUCTION_OLD: {
    name: 'Auctions old',
    alias: '/property-auctions/previous-auction-results'
  },
  NEWHOMES: {
    name: 'New Homes',
    alias: '/new-homes/for-sale'
  },
  PNEWHOMES: {
    name: 'New Homes',
    alias: '/new-homes/previous-developments'
  },
  

  
}


export const TEAM_PAGE_URL = URLS.TEAM;
export const OFFICE_PAGE_URL = URLS.OFFICE;
export const CAREERS_PAGE_URL = URLS.CAREERS;
export const GUIDE_PAGE_URL = URLS.GUIDE;
export const NEWS_PAGE_URL = URLS.NEWS;
export const VLOGS_PAGE_URL = URLS.VLOGS;

export const REVIEWS_PAGE_URL = URLS.REVIEWS
export const FOR_SALE_PAGE_URL = URLS.FOR_SALE;
export const TO_RENT_PAGE_URL = URLS.TO_RENT;
export const PAGE_AUCTION_CURRENT = URLS.AUCTION_CURRENT
export const PAGE_AUCTION_OLD = URLS.AUCTION_OLD
export const PAGE_NEWHOMES = URLS.NEWHOMES
export const PAGE_PNEWHOMES = URLS.PNEWHOMES