// Simplify the gatsby native data to object with key: {imageName} and value: {imagesData}
const convertGatsbyImageNodes = (data) => data.reduce((acc, curr) => {
  const path = curr.relativePath;
  if (!path) {
    return acc;
  }

  const imageName = path.split('/').pop();
  if (!imageName) {
    return acc;
  }

  const regex = /(.jpg)|(.jpeg)|(.png)/;
  const noExtensionImageName = imageName.replace(regex, '');
  acc[noExtensionImageName] = curr.childImageSharp.gatsbyImageData;

  return acc;
}, {});

export {
  convertGatsbyImageNodes
}
