/**
 * External dependencies
 */
import React, { useState, useEffect } from "react"
import { Col, Navbar, Row, Container, Dropdown, Modal } from "react-bootstrap"
import { Link } from "gatsby"
import classNames from "classnames"
import { useStaticQuery, graphql } from "gatsby"
/**
 * Internal dependencies
 */
import GetURL from "../../components/common/site/get-url"
import BurgerMenu from "./burger-menu"
import HeaderMenu from "../header-menu/header-menu"
import CustomToggle from "./header-custom-toggle"
import SearchOverlay from "./SearchOverlay";
import { toCapitalize } from "@components/common/utils";
import "./styles/_index.scss"
import {
  OFFICE_PAGE_URL
} from "../../components/common/site/constants"
const Header = ({ isTsp }) => {
  const [show, setShow] = useState(false)
  const [isPageScroll, setIsPageScroll] = useState(false)
  const [st, stLogo] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // search overlay
  const [showOverlay, setShowOverlay] = useState(false);
  // const [fullscreenOverlay, setFullscreenOverlay] = useState(true);

  function handleShowOverlay(breakpoint) {
    // setFullscreenOverlay(breakpoint);
    setShowOverlay(true);
  }
  // search overlay

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      menuHeaders(publicationState: LIVE, sort: "Sort:asc") {
        Publish
      Sort
      Parent_Label
      Parent_Alias {
        Alias
      }
      Sub_Menus {
        Parent_Label
        Parent_Url {
          Alias
        }
        Add_Sub_menu {
          Label
          Url{
            Alias
          }
          Link
        }
      }
      }
      offices(sort:"Sort:ASC", publicationState: LIVE, where: {Publish: true}) {
        Office_Name
        URL
        Office_Telephone_Lettings
        Office_Telephone_Sales
        Property_Management_number

      }
      contact(publicationState: LIVE) {
        Facebook_Link
        Instagram_Link
        LInkedin_Link
        Twitter_Link
      }
      menuBurgers(publicationState: LIVE, sort: "Sort:asc") {
        Publish
        Sort
        Menu_Parent {
          Parent_Label
          Parent_Url {
            Alias
          }
          Sub_Burger_Menu {
            Label
            Link
            Url {
              Alias
            }
          }
        }
      }
    }
  }
`)
  useEffect(() => {
    window.addEventListener("scroll", () =>
      setIsPageScroll(window.pageYOffset > 0)
    )
    window.addEventListener("scroll", () =>
      stLogo(window.pageYOffset > 110)
    )
  }, [])
  // console.log(data?.glstrapi?.menuBurgers)
  return (
    <header
      className={classNames("header", {
        "is-tsp": isTsp && !isPageScroll,
      })}
    >
      <Container>
        <div className={st ? "header__inner fixed" : "header__inner"}>
          <Navbar>
            <Row className="justify-content-between align-items-center g-0 w-100">
              <Col xs="auto">
                <Link to="/" className="navbar-brand">
                  <i className="icon icon--logo" />
                </Link>
              </Col>

              <Col xs="auto">
                <Row className="g-0 align-items-center">
                  <Col className="header__main-nav d-none d-md-block">
                    <div className="header__link-group">
                      <Row className="g-0">
                        {data.glstrapi?.menuHeaders?.map((item, i) => {
                          return (
                            item?.Parent_Label === "Contact" ?
                              <Col
                                xs="auto"
                                className="d-none d-lg-inline-block ms-md-4_2 ms-xl-4_8 ms-hg-5_6"
                              >
                                <div className="header-dropdown dropdown">
                                  <GetURL
                                    class="menu-link"
                                    label={item?.Parent_Label}
                                    alias={item?.Parent_Alias?.Alias}
                                  />
                                  <div className="menu-top right contact">
                                  <div className="dropdown-menu">
                                    <Row className="g-5">
                                      {data?.glstrapi?.offices?.map((item, k) => {
                                        return (
                                          <Col xs="4" className="mt-2_6">
                                            <div className={`dropdown-inner pb-2_4 ${k > 5 ? '' : 'border-bottom'}`}>
                                              <p className="mb-0_8 dropdown-inner-title"> <Link
                                                className=""
                                                to={OFFICE_PAGE_URL.alias + '/' + item.URL+'/'}
                                              >{toCapitalize(item?.Office_Name)}
                                              </Link>
                                              </p>
                                              {item?.Office_Telephone_Sales &&
                                              
                                                <p className={item?.Office_Telephone_Lettings ? "" : "mb-1_2"}>
                                                  <>Sales:{" "}</>
                                                  <a href={"tel:" + item?.Office_Telephone_Sales}>
                                                    {item?.Office_Telephone_Sales}
                                                  </a>
                                                </p>
                                              
                                            }
                                                {item?.Office_Telephone_Lettings &&
                                              
                                                <p className="mb-1_2">
                                                  <>Lettings:{" "}</>
                                                  <a href={"tel:" + item?.Office_Telephone_Lettings}>
                                                    {item?.Office_Telephone_Lettings}
                                                  </a>
                                                </p>
                                                                                                }
  {item?.Property_Management_number &&
                                              
                                                <p className="mb-1_2">
                                                  <>Property Management:{" "}</>
                                                  <a href={"tel:" + item?.Property_Management_number}>
                                                    {item?.Property_Management_number}
                                                  </a>
                                                </p>
                                                                                                }

                                              <Link
                                                className="c-blue-02 text-decoration-underline"
                                                to={OFFICE_PAGE_URL.alias + '/' + item.URL+'/'}
                                              >
                                                Branch Details
                                              </Link>
                                            </div>
                                          </Col>
                                        )
                                      })}
                                    </Row>
                                  </div>
                                  </div>
                                </div>
                              </Col>
                              :
                              <Col
                                xs="auto"
                                className={
                                  // item?.Parent_Label === "Value My Home" ? "d-none d-md-inline-block ms-md-4_2 ms-xl-4_8 ms-hg-5_6" :
                                   "d-none d-lg-inline-block ms-md-4_2 ms-xl-4_8 ms-hg-5_6"}
                              >
                                <div className="header-dropdown dropdown">
                                  <GetURL
                                    class="menu-link"
                                    label={item?.Parent_Label}
                                    alias={item?.Parent_Alias?.Alias}
                                  />
                                  <div className={item?.Sub_Menus?.length < 3 ? "menu-top" : "menu-top right"}>
                                  <div className={"dropdown-menu"}>
                                    <Row className="g-5">
                                      {item?.Sub_Menus?.map((list, i) => {
                                        return (
                                          <Col xs={item?.Sub_Menus?.length === 1 ? "12" 
                                          : item?.Sub_Menus?.length === 2 ? "6" : "4"} className="mt-2_6">
                                            <div className="dropdown-inner">
                                              {list?.Parent_Label && 
                                              <p className="mb-0_8 dropdown-inner-title">{list?.Parent_Label}</p>
                                              }
                                              {list?.Add_Sub_menu?.map((menu, j) => {
                                                return (
                                                  <p className="menu-link-page">
                                                    <GetURL
                                                      // class="menu-link"
                                                      customLink={menu.Link}
                                                      link={menu?.Link}
                                                      label={menu?.Label}
                                                      alias={menu?.Url?.Alias}
                                                    />
                                                  </p>

                                                )
                                              })}
                                            </div>
                                          </Col>
                                        )
                                      })}
                                    </Row>
                                  </div>
                                </div>
                                </div>
                                {/* <GetURL
                            class=""
                            label={item?.Parent_Label}
                            alias={item?.Parent_Alias?.Alias}
                          /> */}
                                {/* <Link to="/">Find a Home</Link> */}
                              </Col>

                          )
                        })
                        }


                      </Row>
                    </div>
                  </Col>

                  <Col xs="auto">
                    <Row className="header__utils-nav align-items-center">
                      <Col xs="auto">
                        <a href="javascript:void(0)" onClick={() => handleShowOverlay()}>
                          <i className="icon icon--search"></i>
                        </a>
                        <Modal show={showOverlay} fullscreen={true} onHide={() => setShowOverlay(false)} className="search-overlay-modal">
                          <Modal.Header closeButton>
                          </Modal.Header>
                          <Modal.Body className="d-flex align-items-center justify-content-center">
                            <SearchOverlay setShowOverlay={setShowOverlay} />
                          </Modal.Body>
                        </Modal>
                      </Col>

                      <Col xs="auto">
                        <BurgerMenu onClick={handleShow} />
                        <HeaderMenu branches={data?.glstrapi?.offices} data={data?.glstrapi?.contact} menuBurgers={data?.glstrapi?.menuBurgers} show={show} onClick={handleClose} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Navbar>
        </div>
      </Container>
    </header>
  )
}

export default Header
