import React from 'react';
import { Container, Row, Col, Form } from "react-bootstrap"
import ReactMarkdown from "react-markdown/with-html";
import { useStaticQuery, graphql } from "gatsby"
import ContentBlock from "../content/Content";

function PopularSearchStatic(props) {
    const data = useStaticQuery(graphql`
    query  {  
      glstrapi {
        popularSearch {
          General
          Let_Rent
          Buy_Sell
          Auction
        }
      }
    }
  `)
    var footer_data = data.glstrapi.popularSearch;
    var popularSearchtype = props.popularSearch;
    return (
        <>
            {popularSearchtype=='Sell' ?
            <ContentBlock Content={footer_data?.Buy_Sell} />
            : 
            popularSearchtype == 'Rent' ?
            <ContentBlock Content={footer_data?.Let_Rent} />
            : 
            popularSearchtype == 'Auction' ?
            <ContentBlock Content={footer_data?.Auction ? footer_data?.Auction : footer_data?.General} />
            :
            <ContentBlock Content={footer_data?.General} />
    }

        </>
    )
}

export default PopularSearchStatic
