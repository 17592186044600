/**
 * External dependencies
 */
 import { useStaticQuery, graphql } from "gatsby"

 const useFooterLogos = () => {
   const { allFile: { nodes: items } } = useStaticQuery(graphql`
     query FooterLogos {
       allFile(filter: {relativeDirectory: {eq: "footer/images/logos"}}) {
         nodes {
           relativePath
           childImageSharp {
             gatsbyImageData(layout: CONSTRAINED)
           }
         }
       }
     }
  `);
 
   return items;
 }
 
 export default useFooterLogos;