/**
 * External dependencies
 */
import PropTypes from "prop-types"
import React, {useEffect} from "react"
import { useLocation } from '@reach/router';
import classNames from "classnames";
/**
 * Internal dependencies
 */
import Header from "./header/header"
import Footer from "./footer/footer"
import "../styles/main.scss"
import { navigate } from "@reach/router"
function startsWith(str, word) {
  return str?.lastIndexOf(word, 0) === 0;
}
const Layout = ({ children, isTspPage, propertyClass,
   Popular_Search_Type,
   searchtype,
   searchBedroomfield,
  Searcharea, searchPtype, area, type}) => {
  const { pathname } = useLocation();
  const formatClass =
    pathname === "/"
      ? "index"
      : pathname.replace(/[^a-zA-Z-]/g, "");
  
  // const isTspPage = transparentHeaderPages.includes(formatClass);
  useEffect(() => {
    //   const browserZoomLevel1 = Math.round(window.devicePixelRatio * 100);
    //   if(browserZoomLevel1 === 110){
    //     document.body.style.zoom = 0.9
    //   }
    //   else if(browserZoomLevel1 === 125){
    //     document.body.style.zoom = 0.9
    //   }
    //   else if(browserZoomLevel1 === 150){
    //     document.body.style.zoom = 0.7
    //   }
    //   else if(browserZoomLevel1 === 175){
    //     document.body.style.zoom = 0.7
    //   }
    //   else {
    //     document.body.style.zoom = 1
    //   }
    // window.addEventListener('resize', () => {
    //   const browserZoomLevel = Math.round(window.devicePixelRatio * 100);
    //   console.log(browserZoomLevel)
    //   if(browserZoomLevel === 110){
    //     document.body.style.zoom = 0.9
    //   }
    //   else if(browserZoomLevel === 125){
    //     document.body.style.zoom = 0.9
    //   }
    //   else if(browserZoomLevel === 150){
    //     document.body.style.zoom = 0.7
    //   }
    //   else if(browserZoomLevel === 175){
    //     document.body.style.zoom = 0.7
    //   }
    //   else {
    //     document.body.style.zoom = 1
    //   }
    // })
    document.querySelectorAll(".wysiwyg-block a").forEach(node => { 
      node.addEventListener('click', e => { 
        e.preventDefault();
        const isTarget = startsWith(e?.target?.href, '#'); // using href target link to scroll to ID
        const isRelative = startsWith(e?.target?.href, window.location.protocol+"//"+window.location.hostname); // it's a manual relative link
        const isTel = startsWith(e?.target?.href, 'tel:'); // it's a manual relative link
        const isMail = startsWith(e?.target?.href, 'mailto:'); // it's a manual relative link
        const isTargetLength = e?.target?.href?.length > 1; // sometimes a link can have a dummy placeholder '#', so we want to exclude it as it's not a valid target
        window.scroll(0, 0)
        if (isTarget && isTargetLength) {
          navigate(e?.target?.href)
        } else if (isRelative) {
          navigate(e?.target?.href)
        } else if (isTel || isMail) {
          navigate(e?.target?.href)
        } else {
          window?.open(e?.target?.href, "_blank");        
        }
      });
    })
  })
  return (
    <div className={classNames(`page-wrapper bg-grey ${propertyClass}`, {
      'overflow-x-hidden': !(pathname.split('/')[1] === 'property') && !(pathname.split('/')[1] === 'new-homes')
    })}>
      <Header isTsp={isTspPage}/>
      <main className={classNames({
        'pt-0': isTspPage
      })}>{children}</main>
      <Footer 
      searchtype={searchtype}
       searchBedroomfield={searchBedroomfield}
        searchPtype={searchPtype}
         Searcharea={Searcharea}
      Popular_Search_Type={Popular_Search_Type}
      area={area}
      type={type}
       />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
